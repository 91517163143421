import * as React from 'react'
import * as System from '../../../../design-system-webinar'
import { handleNewline } from '../../../../base-webinar/utils'
import {
  ActionLink,
  InnerWrap,
  Title,
  Desc,
  ActionIcon,
  ProfileList,
} from './styled-components'
import { Profile } from './Profile'

export function FramerOrganizer() {
  return (
    <InnerWrap id="new-framer">
      <System.Typography kind="Heading2">Framer Organizer</System.Typography>

      <Desc>
        Framer Korea는 Framer 공식 한국 사용자 커뮤니티입니다. 페이스북 그룹과
        카카오톡 오픈 채팅방에 프레이머 관련 업데이트, 정보, 프로젝트, 학습
        자료를 공유하고, 이벤트를 진행하고 있으며, Q&A도 활발히 이뤄지고
        있습니다. 모두 환영합니다!
      </Desc>
      <ActionLink
        href="https://docs.google.com/forms/d/e/1FAIpQLScUBjYTs3w8L_UuJfy0-P57tpB1PfPCCpySz8Lntm8GS2hRbA/viewform?usp=sf_link"
        target="_blank"
      >
        오픈채팅방에서 함께 성장하기{' '}
        <ActionIcon>
          <Arrow />
        </ActionIcon>
      </ActionLink>
      <ProfileList>
        <Profile
          title="루움"
          desc="Wandering Coder"
          img={require('./images/profile-1.png')}
          link="https://www.youtube.com/channel/UCiy8RPplPzXrrAz29KNIDiA?view_as=subscriber"
        />
        <Profile
          title="연희"
          desc="Pragmatic Designer"
          img={require('./images/profile-2.png')}
          link="https://www.facebook.com/jess0884/"
        />
        <Profile
          title="하찌"
          desc="Wise Designer"
          img={require('./images/profile-3.png')}
          link="http://linkedin.com/in/hacci"
        />
        <Profile
          title="노라"
          desc="Branded Marketer"
          img={require('./images/profile-4.png')}
          link="https://www.youtube.com/channel/UCKnXsu_KIxpQ0oEL-YgL8CQ"
        />
        <Profile
          title="난나"
          desc="Ju-Ju Designer"
          img={require('./images/profile-5.png')}
          link="https://www.youtube.com/channel/UCAr5BM8PpnODoxD3GITsqRw"
        />
        <Profile
          title="유진"
          desc="Inquisitive Designer"
          img={require('./images/profile-6.png')}
          link="https://www.facebook.com/profile.php?id=100004210565613"
        />
      </ProfileList>
    </InnerWrap>
  )
}

FramerOrganizer.defaultProps = {}

export function Arrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16">
      <path
        d="M 1 1 L 9 8 L 1 15"
        fill="transparent"
        strokeWidth="2"
        stroke="rgb(156,32,254)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray=""
      />
    </svg>
  )
}
