import styled, { css } from 'styled-components'
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion'
import { bodyContentWidth } from '../../../../base-webinar/consts'

export const Wrap = styled(motion.div)`
  width: 314px;
  height: 177px;
  /* background: yellowgreen; */
  display: inline-block;
  vertical-align: bottom;
  /* border: 1px solid black; */
  /* transition: 1s all ease; */
  position: relative;
  padding: 16px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  @media (max-width: ${bodyContentWidth}px) {
    width: 100%;
  }
  ${({ bg }) =>
    bg &&
    css`
      background: center / cover no-repeat url(${bg});
    `};
  ${({ active }) =>
    active &&
    css`
      padding-left: 40px;
      padding-bottom: 28px;
      width: 640px;
      height: 360px;
      @media (max-width: ${bodyContentWidth}px) {
        padding-left: 17px;
        padding-right: 17px;
        height: 562px;
      }
    `};
`

export const Image = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const Overlay = styled(motion.div)`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`

export const TextBox = styled(motion.div)`
  /* background: pink; */
  position: absolute;
  bottom: 0;
  left: 0;
  padding: inherit;
  @media (max-width: ${bodyContentWidth}px) {
    width: 100%;
  }
`

export const Speaker = styled(motion.div)`
  display: inline-block;
  /* background: blue; */
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.04em;
  color: #ffffff;
`

export const Title = styled(motion.div)`
  display: inline-block;
  /* background: blue; */

  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.04em;

  color: #ffffff;
`

export const Desc = styled(motion.div)`
  display: inline-block;
  margin-top: 16px;
  width: 456px;
  /* background: green; */
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.04em;

  color: #ffffff;
  @media (max-width: ${bodyContentWidth}px) {
    width: 100%;
  }
`
