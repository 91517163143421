import * as React from 'react'
import styled from 'styled-components'
import * as System from '../../../design-system-webinar'
import { bodyContentWidth } from '../../../base-webinar/consts'

const Wrap = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 30px;
  text-align: center;
  display: none;
  @media (max-width: ${bodyContentWidth}px) {
    display: block;
  }
`

export function FloatingCTA() {
  return (
    <Wrap>
      <System.Button
        text="신청하기"
        link="http://harbor.school/framer-korea-meetup/"
      />
    </Wrap>
  )
}

FloatingCTA.defaultProps = {}
