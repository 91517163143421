import * as React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { bodyContentWidth } from '../../../../base-webinar/consts'
import * as System from '../../../../design-system-webinar'
import {
  InnerWrap,
  Title,
  Title2,
  InfoList,
  Divider,
} from './styled-components'
import { Info } from './Info'

export function NewFramer() {
  return (
    <InnerWrap id="new-framer">
      <Divider />
      <Title>달라진 프레이머</Title>
      <Title2>New Framer</Title2>
      <InfoList>
        <Info
          img={require('./images/new-framer-2.gif')}
          title="무료 플랜"
          description="욕심 많은 프레이머의 발전된 기능을 무료로 사용해 볼 수 있습니다. 뷰어 제한 없이 팀원들과 프로토타이핑을 공유해보세요."
        />
        <Info
          img={require('./images/new-framer-1.gif')}
          title="코딩없이 프로토타이핑"
          description="프레이머에 도전했으나 코딩이 어려웠던 분들을 위해 코딩 없이 프로토타이핑이 가능해졌습니다. 매직모션으로 앱에 생명을 불어넣어 보세요."
        />
        <Info
          img={require('./images/new-framer-3.gif')}
          title="협업 도구"
          description="피그마, 스케치 등 다양한 툴과 연동됩니다. 원격 근무 시대에 나에게 잘 맞는 프로토타이핑 툴로 효율적으로 협업할 수 있습니다."
        />
      </InfoList>
    </InnerWrap>
  )
}

NewFramer.defaultProps = {}
