import * as React from 'react'
import { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { themes } from '../../../../base'
import * as Icon from '../../../../design-system-webinar/components/Icons'
import * as System from '../../../../design-system-webinar'
import { Menu } from './Menu'
import { ColumnLeft, ColumnRight, Wrap, InnerWrap } from './styled-components'

export function Header({ ...props }) {
  return (
    <Wrap>
      <InnerWrap>
        <ColumnLeft>
          <Icon.Logo
            style={{
              verticalAlign: 'middle',
              cursor: 'pointer',
            }}
          />
          <Menu />
        </ColumnLeft>

        <ColumnRight>
          <System.Button
            text="신청하기"
            link="http://harbor.school/framer-korea-meetup/"
          />
        </ColumnRight>
      </InnerWrap>
    </Wrap>
  )
}

Header.defaultProps = {}
