import styled, { css, ThemeContext } from 'styled-components'
import { motion } from 'framer-motion'
import { bodyContentWidth } from '../../../../base-webinar/consts'

// TimeTable
export const InnerWrap = styled.div`
  max-width: ${bodyContentWidth}px;
  margin: 0 auto;
  margin-top: 120px;
  @media (max-width: ${bodyContentWidth}px) {
    margin: 0 20px;
    margin-top: 70px;
  }
`

export const Title = styled.div`
  font-family: GmarketSans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height */

  color: #ffffff;
`

export const BlockList = styled.div`
  display: grid;
  column-gap: 9px;
  margin-top: 50px;
  ${({ active }) => {
    switch (active) {
      case 0:
        return css`
          grid-template-columns: 3fr 4fr 1fr 4fr 5fr 2fr;
        `
      case 1:
        return css`
          grid-template-columns: 2fr 7fr 1fr 4fr 5fr 2fr;
        `
      case 2:
        return css`
          grid-template-columns: 2fr 4fr 1fr 8fr 5fr 2fr;
        `
      case 3:
        return css`
          grid-template-columns: 2fr 4fr 1fr 4fr 9fr 2fr;
        `
      case 4:
        return css`
          grid-template-columns: 2fr 4fr 1fr 4fr 5fr 3fr;
        `
      default:
        return css`
          grid-template-columns: 2fr 4fr 1fr 4fr 5fr 2fr;
        `
    }
  }}
 @media (max-width: ${bodyContentWidth}px) {
   display: none;
  }
`

export const BlockListMobile = styled.div`
  display: none;
  @media (max-width: ${bodyContentWidth}px) {
    display: grid;
    column-gap: 9px;
    margin-top: 50px;
    grid-template-columns: 1fr;
    row-gap: 20px;
    margin-top: 22px;
  }
`

// Block
export const BlockWrap = styled(motion.div)`
  display: inline-block;
  cursor: pointer;
`

export const BlockInnerWrap = styled(motion.div)`
  text-align: center;
  /* whileHover shadow */
  /* box-shadow: 0px 0px 43px #5200ff; */
  background: linear-gradient(245.28deg, #9c20fe 10.05%, #5200ff 96.04%);
  padding: 20px 0px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  ${({ kind }) =>
    kind === 'empty' &&
    css`
      background: #302f2f;
    `}
  @media (max-width: ${bodyContentWidth}px) {
    text-align: left;
    padding: 20px;
    box-shadow: unset !important;
  }
`

export const BlockTime = styled(motion.span)`
  display: inline-block;
  font-family: GmarketSans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */

  color: #ffffff;
  margin-bottom: 14px;
`

export const BlockDescCenter = styled.span`
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;

  ${({ active }) =>
    active &&
    css`
      position: initial;
      top: initial;
      transform: initial;
    `}
`

export const BlockDescLine = styled(motion.span)`
  margin: 0 5px;
  display: inline-block;
  font-family: GmarketSans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */
  color: #ffffff;
  @media (max-width: ${bodyContentWidth}px) {
    display: none;
  }
`

export const BlockDesc = styled(motion.span)`
  display: inline-block;
  font-family: GmarketSans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */
  color: #ffffff;
  @media (max-width: ${bodyContentWidth}px) {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
`

export const BlockTitle = styled(motion.span)`
  display: inline-block;
  font-family: GmarketSans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height */

  color: #ffffff;
`
