import * as React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { AnimateSharedLayout } from 'framer-motion'
import { bodyContentWidth } from '../../../../base-webinar/consts'
import * as System from '../../../../design-system-webinar'
import StaticImage from '../../static-image'

export const InnerWrap = styled.div`
  max-width: ${bodyContentWidth}px;
  margin: 0 auto;
  margin-top: 120px;
  @media (max-width: ${bodyContentWidth}px) {
    margin: 0 20px;
    margin-top: 120px;
  }
`

export const CardList = styled.div`
  margin-top: 26px;
`

export function Workshop() {
  return (
    <InnerWrap id="workshop">
      <System.Typography kind="Heading2">Hands-on Workshop</System.Typography>
      <CardList>
        <AnimateSharedLayout>
          <System.Card
            speaker="연희, 유진 / Framer Korea"
            img={<StaticImage source={require('./images/workshop-1.gif')} />}
            title={`프레이머로 넷플릭스 앱 UI
            프로토 타이핑 하기`}
            kind="workshop"
          />
          <System.Card
            speaker="루움 / Framer Korea"
            img={<StaticImage source={require('./images/workshop-2.gif')} />}
            // img={<StaticImage source={require('./images/framer-motion.gif')} />}
            title={`Magic Motion 프로토타이핑으로
            개발자와 협업하기`}
            kind="workshop"
          />
        </AnimateSharedLayout>
      </CardList>
    </InnerWrap>
  )
}

Workshop.defaultProps = {}
