import * as React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { bodyContentWidth } from '../../../../base-webinar/consts'

const Link = styled.a`
  text-decoration: none;
  color: #ffffff;
  ${({ type }) =>
    type === 'secondary' &&
    css`
      margin-right: 15px;
      @media (max-width: ${bodyContentWidth}px) {
        display: block;
        margin-right: 0;
        margin-bottom: 10px;
      }
    `}
`
const Wrap = styled.button`
  all: unset;
  font-family: 'system-ui', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif, serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  background: linear-gradient(238.77deg, #5200ff -2.95%, #0085ff 101.93%);
  border-radius: 5px;
  padding: 13px 24px;
  cursor: pointer;
  white-space: nowrap;
  ${({ type }) =>
    type === 'secondary' &&
    css`
      /* background: linear-gradient(210.23deg, #9c20fe 10.05%, #5200ff 96.04%); */
      background: linear-gradient(253.45deg, #db00ff 1.79%, #5200ff 100%);
      border-radius: 12px;
      padding: 6px 14px;
    `}
`

export function Button({ text, onClick, type, link, ...props }) {
  const locale =
    typeof text === 'string' ? (text.match(/[A-Za-z0-9]/) ? 'en' : 'ko') : 'en' // check the text is en or ko

  return (
    <Link href={link} target="_blank" type={type}>
      <Wrap onClick={onClick} type={type}>
        {text}
      </Wrap>
    </Link>
  )
}

Button.defaultProps = {
  text: 'Button',
}
