import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components-webinar/layout'
import Image from '../components-webinar/image'
import SEO from '../components-webinar/seo'
import * as System from '../../design-system-webinar'
import { Talks } from '../components-webinar/sections/talks'
import { ShowAndTell } from '../components-webinar/sections/show-and-tell'
import { Workshop } from '../components-webinar/sections/workshop'
import { NewFramer } from '../components-webinar/sections/new-framer'
import { TimeTable } from '../components-webinar/sections/time-table'
import { Notice } from '../components-webinar/sections/notice'
import { FramerOrganizer } from '../components-webinar/sections/framer-organizer'
import { Sponsor } from '../components-webinar/sections/sponsor'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Framer Korea Meetup"
      description="프레이머로 환승할 시간"
      metaImage={require('../images/facebook-2.png')}
    />
    <div id="scroller">
      <System.Top img={<Image filename="main-banner.png" />} />
      <Talks />
      <ShowAndTell />
      <Workshop />
      <NewFramer />
      <TimeTable />
      <Notice />
      <FramerOrganizer />
      <Sponsor />
      <System.FloatingCTA />
    </div>
  </Layout>
)

export default IndexPage
