import React from 'react'
import styled from 'styled-components'
import * as System from '../../design-system'

const Wrap = styled.div``
const Img = styled.img`
  width: 100%;
`

const StaticImage = ({ caption, source, ...props }) => (
  <Wrap>
    <Img src={source} alt={caption} />
  </Wrap>
)

export default StaticImage
