import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

// import Header from './header'
import './layout.css'
import * as System from '../../design-system-webinar'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      style={{
        overflow: 'hidden',
      }}
    >
      <System.Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <System.Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
