import * as React from 'react'
import {
  ProfileWrap,
  ProfileImg,
  ProfileTitle,
  ProfileDesc,
} from './styled-components'

export function Profile({ img, title, desc, link }) {
  return (
    <ProfileWrap href={link} target="_blank">
      <ProfileImg
        src={img}
        whileHover={{
          y: -15,
          rotate: 4,
          // boxShadow: '0px 60px 0px -40px rgb(0, 0, 0)',
        }}
      />
      <ProfileTitle>{title}</ProfileTitle>
      <ProfileDesc>{desc}</ProfileDesc>
    </ProfileWrap>
  )
}

Profile.defaultProps = {
  title: 'title',
  desc: 'desc',
  img: require('./images/profile-1.png'),
}
