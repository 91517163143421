import * as React from 'react'
import { MenuItemWrap, MenuWrap } from './styled-components'
import { doScrolling } from '../../../../base-webinar/utils'

export function Menu() {
  const currentStep = useCurrentStep()
  console.log('currentStep', currentStep)
  return (
    <MenuWrap>
      <MenuItem text="Home" to="" active={currentStep === 0} />
      <MenuItem text="Talks" to="#talks" active={currentStep === 1} />
      <MenuItem
        text="Show and tell"
        to="#show-and-tell"
        active={currentStep === 2}
      />
      <MenuItem text="Workshop" to="#workshop" active={currentStep === 3} />
      <MenuItem text="Time Table" to="#time-table" active={currentStep === 5} />
    </MenuWrap>
  )
}

Menu.defaultProps = {}

const MenuItem = ({ text, to, active }) => (
  <MenuItemWrap
    onClick={() =>
      to ? doScrolling(to, 1000, -150) : doScrolling('body', 1000, 0)
    }
    animate={{
      fontWeight: active ? 700 : 500,
      fontSize: active ? 18 : 16,
    }}
    transition={{
      duration: 0.14,
    }}
  >
    {text}
  </MenuItemWrap>
)

MenuItem.defaultProps = {
  active: false,
}

function getProgress(scroller, focusPoint) {
  const children = scroller.childNodes
  const middle = window.innerHeight * focusPoint
  let prevBottom = children[0].getBoundingClientRect().bottom
  for (let i = 1; i < children.length; i++) {
    const { top, bottom } = children[i].getBoundingClientRect()
    const breakpoint = (prevBottom + top) / 2
    if (middle < breakpoint) {
      return i - 1
    }
    prevBottom = bottom
  }
  return children.length - 1
}

function useCurrentStep() {
  const [progress, setProgress] = React.useState(0)
  const focusPoint = 0.3
  React.useEffect(() => {
    const scroller = document.querySelector('#scroller')
    function onScroll() {
      const newProgress = getProgress(scroller, focusPoint)
      setProgress(newProgress)
    }
    document.addEventListener('scroll', onScroll)
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [])
  return progress
}
