import styled, { css, ThemeContext } from 'styled-components'
import { bodyContentWidth } from '../../../../base-webinar/consts'

export const Wrap = styled.footer`
  background: #040404;
  padding-top: 76px;
  padding-bottom: 200px;
`

export const InnerWrap = styled.div`
  max-width: ${bodyContentWidth}px;
  margin: 0 auto;
  @media (max-width: ${bodyContentWidth}px) {
    margin: 0 20px;
  }
`

export const Row = styled.div`
  position: relative;
`

export const ColumnLeft = styled.div`
  font-family: GmarketSans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;
`

export const ColumnRight = styled.div`
  font-family: GmarketSans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  color: #5a9cff;
  position: absolute;
  right: 0;
  bottom: 0;
`

export const Divider = styled.div`
  background: rgba(255, 255, 255, 0.5);
  height: 1px;
  margin: 18px 0;
`

export const Logo = styled.a`
  text-decoration: none;
  width: 32px;
  /* height: 32px; */
  display: inline-block;
  margin-right: 27px;
`
