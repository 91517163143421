import * as React from 'react'
import styled from 'styled-components'
import { bodyContentWidth } from '../../../base-webinar/consts'

const Wrap = styled.div`
  height: 100%;
  @media (max-width: ${bodyContentWidth}px) {
    height: calc((100vw - 48px) * 0.5625);
  }
`

export function YoutubeVideo({
  text,
  mobileSize,
  desktopSize,
  breakPoint,
  youtubeId,
  ...props
}) {
  return (
    <Wrap>
      <iframe
        src={
          '//www.youtube.com/embed/' +
          youtubeId +
          '?version=3&autoplay=1&loop=1&playlist=' +
          youtubeId + // add id as playlist to repeat one video
          '&mute=1&controls=0'
        }
        style={{
          width: '100%',
          height: '100%',
        }}
        frameBorder="0"
        allowFullScreen
      />
    </Wrap>
  )
}

YoutubeVideo.defaultProps = {
  youtubeId: 'i42t2LeXLe8',
}
