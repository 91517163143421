import styled, { css, ThemeContext } from 'styled-components'
import { bodyContentWidth } from '../../../../base-webinar/consts'

// NewFramer

export const InnerWrap = styled.div`
  max-width: ${bodyContentWidth}px;
  margin: 0 auto;
  margin-top: 337px;
  @media (max-width: ${bodyContentWidth}px) {
    margin: 0 20px;
  }
`

export const Divider = styled.div`
  height: 2px;
  background: white;
  width: 60px;
  margin: 0 auto;
  margin-top: 30px;
  display: none;
  @media (max-width: ${bodyContentWidth}px) {
    display: block;
  }
`

export const Title = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 84px;
  color: #ffffff;
  margin-top: 30px;
  @media (max-width: ${bodyContentWidth}px) {
    font-size: 30px;
    line-height: 43px;
  }
`

export const Title2 = styled.div`
  font-family: GmarketSans;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  line-height: 80px;
  background-image: linear-gradient(15deg, #5200ff, #db00ff 40%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 8px;
  @media (max-width: ${bodyContentWidth}px) {
    font-size: 40px;
    line-height: 40px;
  }
`

export const InfoList = styled.div`
  margin-top: 135px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  gap: 15px;
  @media (max-width: ${bodyContentWidth}px) {
    grid-template-columns: repeat(auto-fit, 100%);
    margin-top: 60px;
    row-gap: 40px;
  }
`

// Info

export const InfoWrap = styled.div``

export const InfoImg = styled.img`
  width: 100%;
`

export const InfoTitle = styled.div`
  margin-top: 30px;
  font-family: 'Noto Sans KR', sans-serif;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.2;
  font-weight: 700;
  @media (max-width: ${bodyContentWidth}px) {
    margin-top: 16px;
  }
`

export const InfoDesc = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.2;
  font-weight: 400;
  margin-top: 8px;
`
