import styled, { css, ThemeContext } from 'styled-components'
import { motion } from 'framer-motion'
import { bodyContentWidth } from '../../../../base-webinar/consts'

// FramerOrganizer
export const InnerWrap = styled.div`
  max-width: ${bodyContentWidth}px;
  margin: 0 auto;
  margin-top: 120px;
  @media (max-width: ${bodyContentWidth}px) {
    margin: 0 20px;
    margin-top: 70px;
  }
`

export const Title = styled.div`
  font-family: GmarketSans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height */

  color: #ffffff;
`

export const Desc = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-top: 24px;
  /* or 133% */
  max-width: 53%;

  color: #ffffff;
  @media (max-width: ${bodyContentWidth}px) {
    max-width: initial;
  }
`

export const ActionLink = styled.a`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  margin-top: 16px;
  display: block;
  color: #9c20fe;
  vertical-align: middle;
  text-decoration: none;
`

export const ActionIcon = styled.span`
  vertical-align: middle;
`

export const ProfileList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  gap: 90px;
  /* justify-content: center; */
  margin-top: 56px;
  @media (max-width: ${bodyContentWidth}px) {
    /* grid-template-columns: 1fr 1fr; */
    /* column-gap: 85px; */
    row-gap: 28px;
    margin-top: 28px;
    grid-template-columns: repeat(auto-fit, 100px);
    justify-content: center;
  }
`

// Profile
export const ProfileWrap = styled.a`
  display: block;
  text-decoration: none;
  position: relative;
  @media (max-width: ${bodyContentWidth}px) {
    min-height: 152px;
  }
`

export const ProfileImg = styled(motion.img)`
  /* width: 136px; */
  width: 100%;
  box-shadow: 0px 0px 0px 0px hsla(0, 0%, 0%, 1);
  border-radius: 100%;
  @media (max-width: ${bodyContentWidth}px) {
    width: 100px;
    margin: 0 auto;
    display: block;
  }
`

export const ProfileTitle = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  text-align: center;

  color: #ffffff;
  margin-top: 11px;

  @media (max-width: ${bodyContentWidth}px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 4px;
  }
`

export const ProfileDesc = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  color: #ffffff;
  margin-top: 5px;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: ${bodyContentWidth}px) {
    font-size: 12px;
    line-height: 24px;
    margin-top: 0px;
  }
`
