import * as React from 'react'
import { useState } from 'react'
import {
  InnerWrap,
  Title,
  BlockList,
  BlockListMobile,
} from './styled-components'
import { Block } from './Block'
import * as System from '../../../../design-system-webinar'

export function TimeTable() {
  const [active, setActive] = useState(-1)
  return (
    <InnerWrap id="time-table">
      <System.Typography kind="Heading2">TimeTable</System.Typography>

      <BlockList active={active}>
        <Block
          callback={() => setActive(0)}
          time="2:00"
          title="Intro"
          desc="10min"
          active={active === 0}
        />
        <Block
          callback={() => setActive(1)}
          time="2:10"
          title="Talk"
          desc="15min per 4 person"
          active={active === 1}
        />
        <Block time="3:25" desc="" kind="empty" />
        <Block
          callback={() => setActive(2)}
          time="3:45"
          title="Show and Tell"
          desc="7min per 5 person"
          active={active === 2}
        />
        <Block
          time="4:20"
          title="Hands-on-Workshop"
          desc="15min per 2 sessions"
          callback={() => setActive(3)}
          active={active === 3}
        />
        <Block
          callback={() => setActive(4)}
          time="4:50"
          title="Outro"
          desc="10min"
          active={active === 4}
        />
      </BlockList>

      <BlockListMobile active={active}>
        <Block
          callback={() => setActive(0)}
          time="2:00"
          title="Intro"
          desc="10min"
          active
        />
        <Block
          callback={() => setActive(1)}
          time="2:10"
          title="Talk"
          desc="15min per 3 person"
          active
        />
        <Block time="3:10" title="Break Time" desc="" kind="empty" />
        <Block
          callback={() => setActive(2)}
          time="3:35"
          title="Show and Tell"
          desc="7min per 6 person"
          active
        />
        <Block
          time="4:20"
          title="Hands-on-Workshop"
          desc="30min"
          callback={() => setActive(3)}
          active
        />
        <Block
          callback={() => setActive(4)}
          time="4:50"
          title="Outro"
          desc="10min"
          active
        />
      </BlockListMobile>
    </InnerWrap>
  )
}

TimeTable.defaultProps = {}
