import * as React from 'react'
import {
  Wrap,
  Title,
  SubTitle,
  Description,
  ColumnLeft,
  ColumnRight,
  Info,
  Info2,
  InnerWrap,
  InfoWrap,
  ButtonWrap,
  ImageWrap,
  Overlay,
  TitleMobile,
  VideoWrap,
} from './styled-components'
import * as System from '../../../../design-system-webinar'

export function Top({ img }) {
  return (
    <Wrap>
      <ImageWrap>{img}</ImageWrap>

      <VideoWrap>
        <System.YoutubeVideo youtubeId="hAPxeoP8wn8" />
      </VideoWrap>

      <Overlay />
      <InnerWrap>
        <ColumnLeft>
          <Title>프레이머로 환승할 시간</Title>
          <TitleMobile>
            프레이머로
            <br />
            환승할 시간
          </TitleMobile>

          <SubTitle>새롭게 거듭난 프레이머 누가 사용하고 있을까요?</SubTitle>

          <Description>
            프레이머에 도전했으나 코딩이 어려웠던 분이 있으신가요? 이제 코딩
            없이도 매직모션을 통해 동적인 디자인이 가능해졌습니다. 디자인과
            프로토타이핑 툴을 통합해서 사용하고 싶은 사람, 개발 없이
            프로토타이핑 하고 싶은 디자이너, 디자이너 없이 프로토타이핑하고 싶은
            개발자까지 다양한 사용자의 니즈를 반영합니다. 제한 시간 내 높은
            수준의 프로토타이핑이 필요하다면 지금 프레이머 사용자들의 이야기를
            들어보세요. 새롭게 거듭난 프레이머를 더 쉽게 사용할 수 있도록, 모두
            환영합니다 🙌
          </Description>
        </ColumnLeft>

        <ColumnRight>
          <ButtonWrap>
            <System.Button type="secondary" text="LIVE" />
            <System.Button type="secondary" text="녹화영상 제공" />
          </ButtonWrap>
          <InfoWrap>
            <Info>
              2020 .7.25
              <br />
              SAT 14:00~17:00
            </Info>
            <Info2>참가 URL 당일 오전 안내</Info2>
          </InfoWrap>
        </ColumnRight>
      </InnerWrap>
    </Wrap>
  )
}

Top.defaultProps = {}
