import * as React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { AnimateSharedLayout } from 'framer-motion'
import { bodyContentWidth } from '../../../../base-webinar/consts'
import * as System from '../../../../design-system-webinar'
import Image from '../../image'

export const InnerWrap = styled.div`
  max-width: ${bodyContentWidth}px;
  margin: 0 auto;
  margin-top: 16px;
  @media (max-width: ${bodyContentWidth}px) {
    margin: 0 20px;
    margin-top: 16px;
  }
`

export const CardList = styled.div`
  margin-top: 26px;
`

export function Talks() {
  return (
    <InnerWrap id="talks">
      <System.Typography kind="Heading2">Talks</System.Typography>
      <CardList>
        <AnimateSharedLayout>
          <System.Card
            speaker="Anne Lee / Framer"
            img={<Image filename="talk-0.png" />}
            // bg={require('./images/talk-0.png')}
            title="Unlocking the Power of Magic Motion"
            desc={`In this short webinar, you'll get to see Framer's very own Anne Lee put Magic Motion to the test, and explore powerful ways to use Animation in Framer. You'll also get a preview of Framer Motion 2, and what's in store for the future of taking your designs and animations to production as quickly as possible.`}
          />
          <System.Card
            speaker="이승미 / 선행 기술 UX"
            img={<Image filename="talk-1.png" />}
            // bg={require('./images/talk-1.png')}
            title="제품디자이너의 Framer X 가지고 놀기"
            desc={`앱이나 웹을 디자인하는 UI/UX 디자이너도 아니고, 아직은 실무에서 쓰고 있지도 않습니다. 전자제품의 물리적인 인터랙션이나 UX에 관심이 많아서, 여기에 Framer X를 활용해보고 있어요. 폴더블같은 새로운 폼팩터가 나오고 있고, 여러 디바이스 간의 연결로 경험이 확장되면서, 이런 새로운 UX에 대한 아이디어들을 실현해볼수 있는 툴이 필요하다고 생각했어요. 거창한 생각과 그렇지 못한 프로토타입들이지만, 야금야금 만들어 온 프로토타입들을 모은 ‘Odder ways of using it’를 소개합니다.
            제품 디자인을 전공했지만 형태보단 제품의 UX, 인터랙션, 선행 기술에 관심이 많다는 걸 나중에 깨달았습니다. 깨닫지 못했을 때 배웠던 약간의 영상과 약간의 코딩이 제품 디자인을 만나서 어찌저찌 관심사에 맞는 일들을 해왔습니다. IT 전자기기 회사, SF영화 미술팀을 거쳐, 지금은 가상현실 AR 디바이스용 서비스를 만드는 스타트업에서 프로젝트를 하고 있어요. 앞으로 어떤 일을 더 하고싶은지 아직 고민이 많습니다.`}
          />
          <System.Card
            speaker="노진욱 / Mercedes-Benz Korea"
            img={<Image filename="talk-2.png" />}
            // bg={require('./images/talk-2.png')}
            title="Data와 함께! Making it Real!"
            desc={`많은 프로토타이핑 툴중에서 Framer가 갖는 독보적인 강점 중 하나! 데이터 값 연동을 통한 프로토타입의 자유도를 높일 수 있다는 것 입니다. 특정 액션과 User flow만 강요하는 프로토타입이 아닌 사용자가 어떤 샘플데이터를 눌러도 진행이 가능한 프로토타입을 만들고, User Testing 까지 초고속으로 진행하게 된 방법과 경험을 공유하고자 합니다. 아...저도 초보 Coder 이랍니다. :)
            
            Summary
            스마트폰 OS를 시작으로 차량 인포테인먼트까지 다양한 스마트 디바이스의 System UI만 9년째 디자인하고 있습니다. 세계 각국의 다양한 사용자들을 상대로 디자인을 하기에 항상 새로운 것을 보고, 배우고, 느끼려고 노력하고 있습니다.`}
          />
          <System.Card
            speaker="Mason / Facebook"
            img={<Image filename="sat-3.png" />}
            title={`프로덕트 디자이너의
          마이크로인터렉션 적용기`}
            desc="페이스북에서 프로덕트 디자이너로서 하는 일이 무엇인지, 제가 프레이머를 어떻게 효과적으로 또는 비효과적으로 사용했는지 경험담을 공유하고 싶습니다."
          />
        </AnimateSharedLayout>
      </CardList>
    </InnerWrap>
  )
}

Talks.defaultProps = {}
