import * as React from 'react'
import { InfoWrap, InfoImg, InfoTitle, InfoDesc } from './styled-components'

export function Info({ img, title, description }) {
  return (
    <InfoWrap>
      <InfoImg src={img} />
      <InfoTitle>{title}</InfoTitle>
      <InfoDesc>{description}</InfoDesc>
    </InfoWrap>
  )
}

Info.defaultProps = {
  img: require('./images/new-framer-1.gif'),
  title: 'title',
  description: 'description',
}
