import * as React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { AnimateSharedLayout } from 'framer-motion'
import { bodyContentWidth } from '../../../../base-webinar/consts'
import * as System from '../../../../design-system-webinar'
import Image from '../../image'

export const InnerWrap = styled.div`
  max-width: ${bodyContentWidth}px;
  margin: 0 auto;
  margin-top: 120px;
  @media (max-width: ${bodyContentWidth}px) {
    margin: 0 20px;
    margin-top: 120px;
  }
`

export const Description = styled.p`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.04em;

  color: #ffffff;
  margin-top: 16px;
`

export const CardList = styled.div`
  margin-top: 26px;
`

export function ShowAndTell() {
  return (
    <InnerWrap id="show-and-tell">
      <System.Typography kind="Heading2">Show-and-Tell</System.Typography>
      <Description>
        Show-and-Tell은 Framer X로 만든 프로젝트를 5분 동안 쇼케이스하는
        자리입니다. Framer Korea 커뮤니티의 구성원들이 Framer X를 어떻게
        사용하고 있는지, 각자의 작업 프로세스에서 Framer X를 더 효율적으로
        사용하는 방법을 공유합니다.
      </Description>
      <CardList>
        <AnimateSharedLayout>
          <System.Card
            speaker="김해연 / (주)글라이드"
            img={<Image filename="sat-1.png" />}
            title="써먹는 Framer"
            kind="show-and-tell"
          />
          <System.Card
            speaker="박준형 / 카카오 엔터프라이즈"
            img={<Image filename="sat-2.png" />}
            title={`UX 디자이너가
            Framer를 배워야하는 이유`}
            kind="show-and-tell"
          />
          <System.Card
            speaker="정혜원 / KT"
            img={<Image filename="sat-4.png" />}
            title={`Framer X로
            실현가능한 상상하기`}
            kind="show-and-tell"
          />
          <System.Card
            speaker="김가현 / 더존비즈온"
            img={<Image filename="sat-0.png" />}
            title="코딩 초보자의 Framer X 사용기"
            kind="show-and-tell"
          />
          <System.Card
            speaker="윤민희 / Class 101"
            img={<Image filename="sat-5.png" />}
            title={`사이드 프로젝트에서의 인터랙션
            (feat. Framer X)`}
            kind="show-and-tell"
          />
        </AnimateSharedLayout>
      </CardList>
    </InnerWrap>
  )
}

ShowAndTell.defaultProps = {}
