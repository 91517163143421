import * as React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { bodyContentWidth } from '../../../../base-webinar/consts'
import * as System from '../../../../design-system-webinar'

export const InnerWrap = styled.div`
  max-width: ${bodyContentWidth}px;
  margin: 0 auto;
  margin-top: 16px;
  padding-bottom: 120px;
  margin-top: 120px;
  @media (max-width: ${bodyContentWidth}px) {
    margin: 0 20px;
    margin-top: 70px;
  }
`

export const Title = styled.div`
  font-family: GmarketSans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height */

  color: #ffffff;
  margin-top: 120px;
`

export const Link = styled.a`
  text-decoration: none;
  display: inline-block;
`

export const ImgList = styled.div`
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fit, ) */
  margin-top: 50px;
`

export const Img = styled.img`
  margin-right: 60px;
  height: 74px;
  @media (max-width: ${bodyContentWidth}px) {
    margin-right: 24px;
    height: 42px;
  }
`

export function Sponsor() {
  return (
    <InnerWrap id="new-framer">
      <System.Typography kind="Heading2">Sponsored by</System.Typography>

      <ImgList>
        <Link href="https://www.framer.com" target="_blank">
          <Img src={require('./images/sponsor-1.png')} />
        </Link>
        <Link href="https://harbor.school" target="_blank">
          <Img src={require('./images/sponsor-2.png')} />
        </Link>
        <Link href="https://noonnu.cc" target="_blank">
          <Img src={require('./images/sponsor-3.png')} />
        </Link>
      </ImgList>
    </InnerWrap>
  )
}

Sponsor.defaultProps = {}
