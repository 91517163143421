import * as React from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import {
  Wrap,
  TextBox,
  Speaker,
  Title,
  Desc,
  Image,
  Overlay,
} from './styled-components'
import { handleNewline } from '../../../../base-webinar/utils'

const defaultTransition = {
  duration: 0.28,
  // duration: 3,
}

export function Card({ speaker, title, desc, bg, kind, img }) {
  const [active, setActive] = useState(false)
  return (
    <Wrap
      active={active}
      onClick={() => kind === 'speaker' && setActive(!active)}
      layoutId={speaker + '-wrap'}
      // bg={bg}
      transition={defaultTransition}
    >
      <Image>{img}</Image>
      <Overlay
        animate={{
          opacity: active || kind === 'workshop' ? 1 : 0,
        }}
      />
      <TextBox layoutId={speaker + '-textbox'}>
        <Speaker
          layout // to prevent distorting itself
          transition={defaultTransition}
        >
          {speaker}
        </Speaker>
        <br />
        <Title
          layout // to prevent distorting itself
          transition={defaultTransition}
        >
          {handleNewline(title)}
        </Title>
        {/* <AnimatePresence> */}
        {active && (
          <>
            <br />
            <Desc
              // layout // to prevent distorting itself (with AnimatePresence)
              initial={{ opacity: 0, y: -150 }}
              animate={{ opacity: 1, y: 0, transition: defaultTransition }}
              exit={{ opacity: 0, transition: defaultTransition }}
            >
              {handleNewline(desc)}
            </Desc>
          </>
        )}
        {/* </AnimatePresence> */}
      </TextBox>
    </Wrap>
  )
}

Card.defaultProps = {
  speaker: 'speaker',
  title: 'title',
  desc:
    'Almost before we knew it, we had left the ground. Almost before we knew it, we had left the ground. Almost before we knew it, we had left the ground. Almost before we knew it, we had left the ground.',
  bg: require('./images/talk-0.png'),
  kind: 'speaker',
}
