import * as React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { bodyContentWidth } from '../../../../base-webinar/consts'
import * as System from '../../../../design-system-webinar'
import { handleNewline } from '../../../../base-webinar/utils'

export const InnerWrap = styled.div`
  max-width: ${bodyContentWidth}px;
  margin: 0 auto;
  margin-top: 120px;
  @media (max-width: ${bodyContentWidth}px) {
    margin: 0 20px;
    margin-top: 70px;
  }
`

export const Title = styled.div`
  font-family: GmarketSans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height */

  color: #ffffff;
`

export const Desc = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-top: 24px;
  /* or 133% */

  color: #ffffff;
`

export function Notice() {
  return (
    <InnerWrap id="notice">
      <System.Typography kind="Heading2">Notice</System.Typography>

      <Desc>
        {handleNewline(`- 7월 25일(토) 당일 오전 접속 가능한 개인별 링크가 메일로 전달됩니다.

        - 취소 및 환불은 (contact@framerkorea.org)을 통해 7/24(금)까지 가능합니다.

        - Hands-on-Workshop과 Networking세션에서는 질문을 위해 마이크가 필요할 수 있습니다.
        
        - 사전에 와이파이 환경이 원활한 곳에서 에어팟 등 마이크를 컴퓨터에 연결해주세요 😊`)}
      </Desc>
    </InnerWrap>
  )
}

Notice.defaultProps = {}
