import styled, { css, ThemeContext } from 'styled-components'
import { bodyContentWidth } from '../../../../base-webinar/consts'

export const Wrap = styled.div`
  /* background: linear-gradient(0deg, #141414 0%, rgba(20, 20, 20, 0.4) 100%); */
  padding: 260px 0px 124px 0px;
  position: relative;
  overflow: hidden;
`

export const ImageWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const VideoWrap = styled.div`
  position: absolute;
  left: -20%;
  top: -20%;
  width: 140%;
  height: 140%;
  @media (max-width: ${bodyContentWidth}px) {
    display: none;
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #141414 0%, rgba(20, 20, 20, 0.4) 100%);
`

export const InnerWrap = styled.div`
  max-width: ${bodyContentWidth}px;
  margin: 0 auto;
  position: relative;
  @media (max-width: ${bodyContentWidth}px) {
    margin: 0 20px;
  }
`

export const ColumnLeft = styled.div`
  max-width: 51%;
  @media (max-width: ${bodyContentWidth}px) {
    max-width: 100%;
  }
`

export const Title = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 76px;
  line-height: 110%;
  /* identical to box height, or 84px */

  color: #ffffff;
  margin-bottom: 30px;
  white-space: nowrap;
  @media (max-width: ${bodyContentWidth}px) {
    display: none;
  }
`

export const TitleMobile = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 76px;
  line-height: 110%;
  /* identical to box height, or 84px */

  color: #ffffff;
  margin-bottom: 30px;
  white-space: nowrap;
  display: none;
  @media (max-width: ${bodyContentWidth}px) {
    white-space: initial;
    font-size: 40px;
    line-height: 120%;
    display: block;
  }
`

export const SubTitle = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  color: #ffffff;
  margin-bottom: 25px;
  @media (max-width: ${bodyContentWidth}px) {
    font-size: 16px;
    line-height: 150%;
  }
`

export const Description = styled.p`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  color: #ffffff;
  @media (max-width: ${bodyContentWidth}px) {
    font-size: 16px;
    line-height: 150%;
  }
`

export const ColumnRight = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: inherit;
  @media (max-width: ${bodyContentWidth}px) {
    position: relative;
    margin-top: 40px;
  }
`

export const ButtonWrap = styled.div`
  @media (max-width: ${bodyContentWidth}px) {
    display: inline-block;
    vertical-align: top;
  }
`

export const InfoWrap = styled.div`
  @media (max-width: ${bodyContentWidth}px) {
    display: inline-block;
    margin-left: 12px;
  }
`

export const Info = styled.p`
  font-family: GmarketSans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
  /* or 48px */

  color: #ffffff;
  margin-top: 10px;
  @media (max-width: ${bodyContentWidth}px) {
    font-size: 18px;
    line-height: 150%;
    margin-top: 0;
  }
`

export const Info2 = styled.p`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 150%;
  /* identical to box height, or 33px */

  color: #ffffff;
  margin-top: 2px;
  @media (max-width: ${bodyContentWidth}px) {
    font-size: 12px;
    line-height: 150%;
  }
`
