import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { bodyContentWidth, defaultTransition } from '../../base-webinar/consts'

const Wrap = styled(motion.div)`
  @media (max-width: ${bodyContentWidth}px) {
    height: 100%;
  }
`
const RenderImg = styled(motion.img)`
  width: 100%;
  height: 100%;
`

export function ImageComp({ renderedImg, image, caption, height, ...props }) {
  return (
    <Wrap layout transition={defaultTransition}>
      {renderedImg || <RenderImg src={image} />}
    </Wrap>
  )
}

const GatsbyImgWrap = styled(motion.div)`
  @media (max-width: ${bodyContentWidth}px) {
    height: 100%;
    div {
      height: 100%;
    }
  }
`

const Image = ({ filename, caption, ...props }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n =>
        n.node.relativePath.includes(filename)
      )
      if (!image) {
        return null
      }
      return (
        <ImageComp
          renderedImg={
            <GatsbyImgWrap layout transition={defaultTransition}>
              <Img alt={caption} fluid={image.node.childImageSharp.fluid} />
            </GatsbyImgWrap>
          }
        />
      )
    }}
  />
)

export default Image
