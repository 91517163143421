import * as React from 'react'
import { useEffect } from 'react'
import {
  BlockWrap,
  BlockTime,
  BlockTitle,
  BlockDesc,
  BlockInnerWrap,
  BlockDescCenter,
  BlockDescLine,
} from './styled-components'
import { useHover } from './use-hover'
import { defaultTransition } from '../../../../base-webinar/consts'

export function Block({ time, title, desc, kind, callback, active }) {
  const [hoverRef, isHover] = useHover()

  useEffect(() => {
    if (isHover && callback) callback()
  }, [isHover])

  return (
    <BlockWrap ref={hoverRef} layout transition={defaultTransition}>
      <BlockTime layout transition={defaultTransition}>
        {time}
      </BlockTime>
      <BlockInnerWrap
        kind={kind}
        initial={{
          borderRadius: 12,
        }}
        layout
        transition={defaultTransition}
        animate={{
          boxShadow: active ? '0px 0px 43px #5200FF' : '0px 0px 0px #5200FF',
        }}
      >
        <BlockTitle layout transition={defaultTransition}>
          {title}
        </BlockTitle>
        <BlockDescCenter active={active}>
          <BlockDescLine
            layout
            transition={defaultTransition}
            animate={{
              opacity: active ? 1 : 0,
              x: active ? 0 : -100,
            }}
          >
            -
          </BlockDescLine>
          <BlockDesc
            layout
            transition={defaultTransition}
            animate={{
              opacity: active ? 1 : 0,
              x: active ? 0 : -100,
            }}
          >
            {desc}
          </BlockDesc>
        </BlockDescCenter>
      </BlockInnerWrap>
    </BlockWrap>
  )
}

Block.defaultProps = {}
