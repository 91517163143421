import styled, { css, ThemeContext } from 'styled-components'
import { bodyContentWidth } from '../../../base-webinar/consts'

export const Wrap = styled.p`
  font-family: GmarketSans;
  color: #ffffff;
  ${({ kind }) =>
    kind === 'Heading2' &&
    css`
      font-weight: bold;
      font-size: 30px;
      line-height: 30px;
    `}
  @media (max-width: ${bodyContentWidth}px) {
    font-size: 20px;
    line-height: 20px;
  }
`
